import utils from './lib/utils.js'

if (utils.reduceMotion) {
  // Do nothing.
} else {
  gsap.registerPlugin(ScrollTrigger);
  gsap.defaults({
    ease: "power2.in",
    duration: 0.5,
  });

  // Cards section
  gsap.set('section.cards', {perspective: 800})
  gsap.set('section.cards .card', {transformStyle:'preserve-3d',backfaceVisibility:'hidden'})
  const cards_tl = gsap.timeline({
    scrollTrigger: {
      trigger: 'section.cards',
      start: 'top 70%'
    },
  })
  const cards_cards = document.querySelectorAll('section.cards .card')
  cards_tl.from(cards_cards, { y: 100, rotationY: -30, opacity: 0, stagger: 0.25 })

  // Symptom Section
  const symptom_tl = gsap.timeline({
    scrollTrigger: {
      trigger: 'section.symptom',
      start: 'top 80%'
    },
  })
  const symptom_shinethrough = new SplitType('section.symptom .shine-through', { types: 'chars' })
  symptom_tl.from(symptom_shinethrough.chars, { y: 20, x: 20, opacity: 0, stagger: 0.01 })
}

const btnterms = document.querySelector('button[data="terms"]')
btnterms.addEventListener('click', () => {
  const copayTerms = document.querySelector('.terms')
  copayTerms.classList.toggle('show')
})

